<template>
  <div class="click-game text-center">
    <h3>Presale</h3>
    <div v-if="supply">
      Common Cards Left: {{ supply.maxTotalSupply }}
    </div>

    <div class="mt-5">Quantity: {{ quantity }}</div>
    <div class="d-flex justify-center">
      <v-slider
        v-model="quantity"
        :max="limit - userTokensCount"
        min="1"
      ></v-slider>
    </div>

    <div>
      <div>Price: {{ price / 10**18 }}</div>
      <div>Amount: {{ amount.toFixed(2) }}</div>
    </div>
    {{ amount.toFixed(2)  }} - {{ (allowance / 10**18).toFixed(2) }}

    <div class="mt-5">
      <v-btn
        color="info"
        class="mb-2"
        @click="approve"
        :loading="approveLoading"
        v-if="checkAllowance"
      >
        approve
      </v-btn>
      <v-btn
        color="info"
        class="mb-2"
        @click="register"
        :disabled="userTokensCount >= limit"
        v-else
      >
        buy
      </v-btn>
      <br>
      <br>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'game',

  data() {
    return {
      quantity: 1,
      approveLoading: false,
      buyLoading: false,
    };
  },

  computed: {
    ...mapGetters('Sidus', ['price', 'supply', 'allowance', 'tokenId', 'limit', 'userTokensCount']),

    amount() {
      return (this.price / 10 ** 18) * this.quantity;
    },

    amountForApprove() {
      return (this.price / 10 ** 18) * this.limit;
    },

    checkAllowance() {
      return this.price * this.quantity > this.allowance;
    },
  },

  methods: {
    ...mapActions({
      approveMilk: 'Sidus/approveMilk2',
      registerForPreSale: 'Sidus/registerForPreSale',
    }),

    async register() {
      try {
        this.buyLoading = true;
        await this.registerForPreSale(this.quantity);
        this.buyLoading = false;
      } catch (e) {
        this.buyLoading = false;
        console.log(e);
      }
    },

    async approve() {
      try {
        this.approveLoading = true;
        await this.approveMilk(this.amountForApprove);
        this.approveLoading = false;
      } catch (e) {
        this.approveLoading = false;
        console.log(e);
      }
    },
  },

  async mounted() {
    setTimeout(async () => {
      await this.$store.dispatch('Sidus/getPresaleInfo');
    }, 500);

    setInterval(async () => {
      await this.$store.dispatch('Sidus/getPresaleInfo');
    }, 5000);
  },
};
</script>

<style lang="scss">
.click-game{
  font-size: 2rem !important;
  .v-btn__content{
    font-size: 1.4rem !important;
  }
  .v-input{
    max-width: 200px;
  }
}
</style>
